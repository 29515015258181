import RadialProgressBar from "vue-radial-progress";

export default {
    name: "ManualMonitoringLinkItem",
    props: ["url"],
    components: {RadialProgressBar},
    data() {
        return {
            edit: false,
        };
    },
    methods: {
        onSave() {
            this.edit = false;
            this.$emit("change", this.url);
        },
    },
};
